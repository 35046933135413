import React, {useEffect, useMemo, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";

import "./SearchModal.scss"
import classes from "./SearchModal.scss.json"

import AgencyPicker from "./AgencyPicker";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";

import cx from "classnames"

import {
    addDays,
    differenceInDays,
    differenceInHours,
    format,
    getDay,
    isAfter,
    isBefore,
    isValid,
    parse,
    parseISO, setHours, getHours,
    startOfDay
} from "date-fns"
import sandbox from "../../../service/Sandbox";
import frLocale from "date-fns/locale/fr"

const searchModalDisplayMode = {
    start_agency: 'start_agency',
    start_day: 'start_day',
    start_hour: 'start_hour',
    end_agency: 'end_agency',
    end_day: 'end_day',
    end_hour: 'end_hour',
    recap: 'recap',
}

const title = {
    start_agency: 'Agence de départ',
    start_day: 'Jour de début',
    start_hour: 'Heure de début',
    end_agency: 'Agence de Retour',
    end_day: 'Jour de retour',
    end_hour: 'Heure de retour',
}

const dateStepOrder = ['start_day', 'start_hour', 'end_day', 'end_hour']

const SearchModal = ({selectedInput, toggleOpen, agencies, onChange, values, onSearch, color, bookingParameters, singleAgency = false, minDays, minHours = 2, minStartDate, maxStartDate,maxTimeBeforeStart, disableAgencySelect = false, warningMessage}) => {

    const [modalOpen, setModalOpen] = useState(false)
    const [displayMode, setDisplayMode] = useState(selectedInput);
    const [search, setSearch] = useState({
        start_agency: values?.start_agency ?? null,
        end_agency: values?.end_agency ?? null,
        start_day: '',
        end_day: '',
        start_hour : '',
        end_hour : '',
    })

    const [startAgencyHours, setStartAgencyHours] = useState([]);
    const [endAgencyHours, setEndAgencyHours] = useState([]);
    const [startUnavailableDays, setStartUnavailableDays] = useState([]);
    const [endUnavailableDays, setEndUnavailableDays] = useState([]);
    const [startHoursAndMinutes, setStartHoursAndMinutes] = useState([]);
    const [endHoursAndMinutes, setEndHoursAndMinutes] = useState([]);

    const startAgency = useMemo(() => {
        if(search.start_agency && agencies?.length) {
            return agencies?.find(item => item.id === Number(search.start_agency))
        }
        return null
    }, [search.start_agency, agencies]);

    const endAgency = useMemo(() => {
        if(search.end_agency && agencies?.length) {
            return agencies?.find(item => item.id === Number(search.end_agency))
        }
        return null
    }, [search.end_agency, agencies]);


    useEffect(() => {
        let day = null
        if (values.start_date) {
            const stDate = isValid(values.start_date) ? values.start_date : parseISO(values.start_date)
            day = getDay(stDate);
        }
            let hours = []
            let hourAndMinutes = []
            let unavailableDays = []
            if (values?.start_agency){
                const agency = agencies?.find(a => a.id == values?.start_agency)
                if (agency?.availability_periods?.length > 0){
                    agency?.availability_periods?.forEach(period => {
                        if (day && period?.day === day && period.status === 'available') {
                            hours = [...hours, ...sandbox.range(Number(period?.start_hour?.split(':')[0]), Number(period?.end_hour?.split(':')[0]) + 1)]
                            if (["30"].includes(period?.start_hour?.split(':')[1])) {
                                hourAndMinutes = [...hourAndMinutes, {time: period?.start_hour, type: "start"} ]
                            }
                            if (["00","30"].includes(period?.end_hour?.split(':')[1])) {
                                hourAndMinutes = [...hourAndMinutes, {time: period?.end_hour, type: "end"} ]
                            }
                        }
                        
                        if (period.status === 'unavailable' && period.all_day){
                            unavailableDays = [...unavailableDays, period?.day]
                        }
                    })
                }
            }
            hours?.sort((a, b) => a - b)
            setStartAgencyHours(hours)
            setStartHoursAndMinutes(hourAndMinutes)
            console.log({unavailableDays, hours} );
            setStartUnavailableDays(unavailableDays)
        
    }, [values.start_date, values?.start_agency, agencies]);

    useEffect(() => {
        if (values.start_date && startAgencyHours?.length) {
            const stDate = isValid(values.start_date) ? values.start_date : parseISO(values.start_date)
            const startHour =  Number(format(stDate, "H"))
            const minHour = startAgencyHours?.[0]
            const maxHour = startAgencyHours?.[startAgencyHours?.length - 1]
            if(startHour < minHour) {
                let updated = {...search}
                const hourValue = `${sandbox.numberPad(minHour, 2)}:00`
                updated.start_hour = hourValue
                updated.end_hour = hourValue
                setSearch(() => updated)
                const res = formatResult(updated)
                console.log({updated, res})
    //             onChange && onChange({...res})
            } else if(startHour > maxHour) {
                let updated = {...search}
                const hourValue = `${sandbox.numberPad(maxHour, 2)}:00`
                updated.start_hour = hourValue
                updated.end_hour = hourValue
                setSearch(() => updated)
                const res = formatResult(updated)
                console.log({res, updated})
    //             onChange && onChange({...res})
            }
        }
    }, [startAgencyHours, values.start_date]);


    useEffect(() => {
        if (values.end_date) {
            const endDate = isValid(values.end_date) ? values.end_date : parseISO(values.end_date)
            const day = getDay(endDate);
            let hours = []
            let hourAndMinutes = []
            let unavailableDays = []
            if (values?.end_agency){
                const agency = agencies?.find(a => a.id == values?.end_agency)
                if (agency?.availability_periods?.length > 0){
                    agency?.availability_periods?.forEach(period => {
                        if (period?.day === day && period.status === 'available') {
                            hours = [...hours, ...sandbox.range(Number(period?.start_hour?.split(':')[0]), Number(period?.end_hour?.split(':')[0]) + 1)]
                        }
                        if (["30"].includes(period?.start_hour?.split(':')[1])) {
                            hourAndMinutes = [...hourAndMinutes, {time: period?.start_hour, type: "start"} ]
                        }
                        if (["00","30"].includes(period?.end_hour?.split(':')[1])) {
                            hourAndMinutes = [...hourAndMinutes, {time: period?.end_hour, type: "end"} ]
                        }

                        if (period.status === 'unavailable' && period.all_day){
                            unavailableDays = [...unavailableDays, period?.day]
                        }

                    })
                }
            }
            hours?.sort((a, b) => a - b)
            setEndAgencyHours(hours)
            setEndHoursAndMinutes(hourAndMinutes)
            setEndUnavailableDays(unavailableDays)
        }
    }, [values.end_date, values?.end_agency, agencies]);

    const [inputModeValues, setInputModeValues] = useState()
    const [durationError, setDurationError] = useState([])

    const isAgencyDisplayMode = useMemo(() => {
        return [searchModalDisplayMode.start_agency, searchModalDisplayMode.end_agency].includes(displayMode)
    }, [displayMode]);

    useEffect(() => {
        setSearch(prevState => {
            const newState = {...prevState}
            if(isValid(values?.start_date)) {
                newState.start_day = format(values?.start_date, "yyyy-MM-dd")
                newState.start_hour = format(values?.start_date, "HH:mm")
            } else if(values?.start_date !== '') {
                newState.start_day = values?.start_date?.split(' ')[0] ?? ''
                newState.start_hour = values?.start_date?.split(' ')[1] ?? ''
            }
            if(isValid(values?.end_date)) {
                newState.end_day = format(values?.end_date, "yyyy-MM-dd")
                newState.end_hour = format(values?.end_date, "HH:mm")
            } else if(values?.end_date != null && values?.end_date !== '') {
                newState.end_day = values?.end_date?.split(' ')[0] ?? ''
                newState.end_hour = values?.end_date?.split(' ')[1] ?? ''
            } else {
                newState.end_day = ''
                newState.end_hour = ''
            }
            if(values?.start_agency) {newState.start_agency = values.start_agency}
            if(values?.end_agency) {newState.end_agency = values.end_agency}
            return newState
        })
    }, [values])
    useEffect(() => {
        if(search.start_agency == null) {
            const defaultAgency = agencies?.find((item) => item.enable_on_website && item.is_default)
            if(defaultAgency) {
                setSearch(prevState => {
                    const vals = {...prevState, start_agency: defaultAgency.id, end_agency: defaultAgency.id}
                    return vals
                })
            }
        }
    }, [agencies])
    useEffect(() => {
        if(values) {
            setSearch(prevState => {
                let newState = {...prevState}
                if(isValid(values?.start_date)) {
                    newState = {
                        ...newState,
                        start_day: format(values?.start_date, 'yyyy-MM-dd') ?? '',
                        start_hour: format(values?.start_date, 'HH:mm') ?? '',
                    }
                }
                if(isValid(values?.end_date)) {
                    newState = {
                        ...newState,
                        end_day: format(values?.end_date, 'yyyy-MM-dd') ?? '',
                        end_hour: format(values?.end_date, 'HH:mm') ?? '',
                    }
                }
                return newState
            })
        }
    }, [selectedInput])
    useEffect(() => {
        if(selectedInput) {
            setModalOpen(true)
            setDisplayMode(selectedInput)
        }
    }, [toggleOpen,selectedInput]);
    useEffect(() => {
        const result = formatResult()
        setInputModeValues(prevState => {
            const newState = {...prevState}
            if(result?.end_agency !== '') {
                newState.start_agency = sandbox.getAgencyName(agencies,result.start_agency)
            }
            if(result?.end_agency !== '') {
                newState.end_agency = sandbox.getAgencyName(agencies,result.end_agency)
            }
            if(result.start_date) {
                newState.start_day = format(result.start_date, 'dd/MM/yyyy')
                newState.start_hour = format(result.start_date, 'HH:mm')
            }
            if(result.end_date) {
                newState.end_day = format(result.end_date, 'dd/MM/yyyy')
                newState.end_hour = format(result.end_date, 'HH:mm')
            }
            return newState
        })
    }, [search])

    const handleChange = ({name, value}, goToNextStep = true) => {
        const errors = []
        let updated = {...search}
        setSearch(prevState => {
            let newState = {...prevState}
            if(name === "start_agency" ) {
                newState = {...prevState, end_agency: value}
            } else if (name === "start_day") {
                if(value >= newState.end_day ) {
                    const daysToAdd = Math.max(minDays, 1)
                    newState.end_day = format(addDays(parseISO(value), daysToAdd), "yyyy-MM-dd")
                }
            }
            if(name === 'start_hour') {
                newState.end_hour = value
            }
            updated = {...newState, [name] : value}
            return updated
        })
        if(name === "start_agency" ) {
            updated = {...updated, end_agency: value}
        } else if (name === "start_day") {
            if(startAgencyHours?.length) {
                const startHour =  Number(updated.start_hour?.split(':')?.[0])

                console.log({startHour, startAgencyHours})
            }

            if(value >= updated.end_day ) {
                const daysToAdd = Math.max(minDays, 1)
                updated.end_day = format(addDays(parseISO(value), daysToAdd), "yyyy-MM-dd")
            }
        }
        if(name === 'start_hour') {
            updated.end_hour = value
        }
        updated = {...updated, [name] : value}
        const res = formatResult(updated)
        if(!isAgencyDisplayMode && isValid(res.start_date) && isValid(res.end_date)) {
            let formValid = true
            // MIN_DAYS
            const hourDiff = Math.abs(differenceInHours(res.start_date, res.end_date))
            const minAvailableHours = minDays ? parseInt(minDays) * 24 : minHours
            if (displayMode === searchModalDisplayMode.start_hour || displayMode === searchModalDisplayMode.end_hour) {
                if(hourDiff < minAvailableHours && (Math.ceil(hourDiff/24) < minDays) ) {
                    if(minDays) {
                        errors.push(`La durée minimale de location est de ${minDays} jours`)
                    } else {
                        errors.push(`La durée minimale de location est de ${minAvailableHours} heures`)
                    }
                    formValid = false
                }
            }
            //MIN TIME BEFORE START
            if ( isBefore(startOfDay(res.start_date), startOfDay(minStartDate))  ) {
                // errors.push(`L'agence n’accepte les reservation qu’après le ${format(minStartDate,'dd MMMM yyyy', {locale: frLocale})}`)
                errors.push(`Veuillez sélectionner une date de réservation à partir du ${format(minStartDate,'dd MMMM yyyy', {locale: frLocale})} pour cette agence.`)
                formValid = false
            }

            //MAX TIME BEFORE START
            if ( isAfter(startOfDay(res.start_date), startOfDay(maxStartDate))  ) {
                // errors.push(`L'agence n’accepte les reservation qu’avant le ${format(maxStartDate,'dd MMMM yyyy', {locale: frLocale})}`)
                errors.push(`Les réservations sont possibles jusqu'à ${maxTimeBeforeStart} mois à l'avance pour cette agence.`)
                formValid = false
            }

            if(formValid) {
                onChange && onChange({...res})
                goToNextStep && showNextDisplayMode()
            }
        }
        else {
            onChange && onChange({...res})
            goToNextStep && showNextDisplayMode()
        }
        setDurationError(errors)
        setInputModeValues(updated)
    }
    const handleModalClose = () => {
        setModalOpen(false)
    }
    const showNextDisplayMode = () => {
        // const keyList = Object.keys(searchModalDisplayMode);
        if(dateStepOrder.includes(displayMode)) {
            const currentStep = dateStepOrder.indexOf(displayMode)
            if(currentStep != null && ((currentStep+1) < dateStepOrder.length) ) {
                const nextKey = dateStepOrder[currentStep + 1]
                setDisplayMode(searchModalDisplayMode[nextKey])
            } else {
                setModalOpen(false)
            }
        } else {
            setModalOpen(false)
        }
    }
    const handleBack = () => {
        const keyList = Object.keys(searchModalDisplayMode);
        const currentStep = keyList.indexOf(displayMode)
        if(currentStep != null && ((currentStep-1) >= 0) ) {
            const nextKey = keyList[currentStep - 1]
            setDisplayMode(searchModalDisplayMode[nextKey])
        } else {
            setModalOpen(false)
        }
    }
    const formatResult = (updated) => {
        const data = updated ?? search
        let startDate = null
        let endDate = null
        if( data.start_day !== '') {
            let startDateStr
            let hourValue = null
            if(data.start_hour !== '') {
                hourValue = data.start_hour
            }
            hourValue = getAgencyAvailabilityLimit(startAgency?.availability_periods, hourValue, data.start_day)
            if(hourValue === null) {
                startDate = parse(`${data.start_day}`, 'yyyy-MM-dd', new Date())
            } else {
                startDateStr = `${data.start_day} ${hourValue}`
                startDate = parse(startDateStr, 'yyyy-MM-dd HH:mm', new Date())
            }
        }
        if(data.end_day !== '') {
            let endDateStr
            let hourValue = null
            if(data.end_hour !== '') {
                hourValue = data.end_hour
            }
            hourValue = getAgencyAvailabilityLimit(endAgency?.availability_periods, hourValue, data.end_day)

            if(hourValue === null) {
                endDate = parse(`${data.end_day}`, 'yyyy-MM-dd', new Date())
            } else {
                endDateStr = `${data.end_day} ${hourValue}`
                endDate = parse(endDateStr, 'yyyy-MM-dd HH:mm', new Date())
            }
        }
         const result = {
            start_agency: data.start_agency,
            end_agency: data.end_agency,
            start_date: isValid(startDate) ? startDate : null,
            end_date: isValid(endDate) ? endDate : null,
        }
        // console.log(result)
        return result
    }

    const getAgencyAvailabilityLimit = (availabilityPeriods = [], hour, date) => {
        const periods = availabilityPeriods.filter(period => {
            const day = getDay(parseISO(date))
            return day === period.day
        })
        if(periods?.length === 0 && hour === null) { return "12:00" }

        if(hour?.length === 4) { hour = `0${hour}` }

        let minHour = null
        let maxHour = null

        periods?.forEach(period => {
            if (minHour === null || period.start_hour < minHour) {
                minHour = period.start_hour
            }
            if (maxHour === null || period.end_hour > maxHour) {
                maxHour = period.end_hour
            }
        })
        if(hour === null) { return  minHour }
        if(minHour != null && minHour >= hour){ return minHour }
        if(maxHour != null && maxHour <= hour){ return maxHour }
        return hour
    }

    const handleSearchButtonClick = () => {
        setModalOpen(false)
        onSearch && onSearch(formatResult())
    }
    const handleDisplayModeChange = (newMode) => {
        setDisplayMode(newMode)
    }

    return (
        <Modal
            dialogClassName={classes.modal}
            show={modalOpen}
            fullscreen={"xl-down"}
            backdropClassName={'bg-transparent'}
            contentClassName={"w-100"}
            onHide={() => setModalOpen(false)}
            centered
            animation={true}
        >
            <ModalHeader className={cx(classes['modal-title'], "d-flex flex-column px-2")}>
                <div className={'d-flex justify-content-between w-100 align-items-center'}>
                    <p className={"medium fs18"}>{title[displayMode]}</p>
                    <Button onClick={handleModalClose}>Fermer</Button>
                </div>
                {dateStepOrder.includes(displayMode) ? <>
                    <div className={"d-flex flex-column w-100 mt-1 border-top pt-1"}>
                        <div className={"d-flex justify-content-between w-100 mb-1"}>
                            <div className={"d-flex w-100 pe-1"}>
                                <div className={"w-100"}>
                                    <input
                                        className={cx("form-control px-1 text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.start_day})}
                                        placeholder={"date"}
                                        readOnly
                                        onClick={() => handleDisplayModeChange(searchModalDisplayMode.start_day)}
                                        value={inputModeValues?.start_day ?? ""}
                                    />
                                    {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.start_day)} className={"w-100 mx-1"}>Start date</Button>*/}
                                </div>
                                <div className={"ps-1 w-75"}>
                                    <input
                                        className={cx("form-control text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.start_hour})}
                                        placeholder={"heure"}
                                        onClick={() => handleDisplayModeChange(searchModalDisplayMode.start_hour)}
                                        readOnly
                                        value={inputModeValues?.start_hour ?? ""}
                                    />
                                    {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_day)} className={"w-100"}>End date</Button>*/}
                                </div>
                            </div>
                            <div className={"d-flex w-100"}>
                                <div className={"pe-1 w-100"}>
                                    <input
                                        className={cx("form-control px-1 text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.end_day})}
                                        placeholder={"date"}
                                        onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_day)}
                                        readOnly
                                        value={inputModeValues?.end_day ?? ""}
                                    />
                                    {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_date)} className={"w-100 mx-1"}>Start hour</Button>*/}
                                </div>
                                <div className={"ps-1 w-75"}>
                                    <input
                                        className={cx("form-control text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.end_hour})}
                                        placeholder={"heure"}
                                        onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_hour)}
                                        readOnly
                                        value={inputModeValues?.end_hour ?? ""}
                                    />
                                    {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_hour)} className={"w-100"}>End hour</Button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <></>}
            </ModalHeader>
            <ModalBody>
                {durationError?.length > 0 && (
                    <div className={"bg-danger-light p-3 text-center c-danger mb-2"}>
                        {durationError.map((item,index) => {
                            return <p className={"medium"} key={`duration-error-item-${index}`}>{item}</p>
                        })}
                    </div>
                )}
                {displayMode === searchModalDisplayMode.recap && (
                    <>
                        <div className={"d-flex flex-column w-100"}>
                            {agencies?.length > 1 && !disableAgencySelect && (
                                <div className={"w-100 pe-1"}>
                                    <p>Agence{!singleAgency && " de départ"}</p>
                                    <input
                                        className={cx("form-control text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.start_agency})}
                                        readOnly
                                        placeholder={"agence de départ"}
                                        onClick={() => handleDisplayModeChange(searchModalDisplayMode.start_agency)}
                                        value={inputModeValues?.start_agency ?? ""}
                                    />
                                    {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.start_agency)} className={"w-100 mx-1"}>Start agency</Button>*/}
                                </div>
                            )}
                            <div className={"w-100 my-3"}>
                                <p>Date de départ</p>
                                <div className={"d-flex w-100"}>
                                    <div className={"w-100"}>
                                        <input
                                            className={cx("form-control px-1 text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.start_day})}
                                            placeholder={"date"}
                                            id={'startDate'}
                                            readOnly
                                            onClick={() => handleDisplayModeChange(searchModalDisplayMode.start_day)}
                                            value={inputModeValues?.start_day ?? ""}
                                        />
                                        {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.start_day)} className={"w-100 mx-1"}>Start date</Button>*/}
                                    </div>
                                    <div className={"ps-1 w-75"}>
                                        <input
                                            className={cx("form-control text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.start_hour})}
                                            placeholder={"heure"}
                                            onClick={() => handleDisplayModeChange(searchModalDisplayMode.start_hour)}
                                            readOnly
                                            value={inputModeValues?.start_hour ?? ""}
                                        />
                                        {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_day)} className={"w-100"}>End date</Button>*/}
                                    </div>
                                </div>
                            </div>
                            {(agencies?.length > 1 && !singleAgency && !disableAgencySelect) && (
                                <div className={"w-100"}>
                                    <p>Agence de retour</p>
                                    <input
                                        className={cx("form-control text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.end_agency})}
                                        onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_agency)}
                                        placeholder={"agence de départ"}
                                        value={inputModeValues?.end_agency ?? ""}
                                        readOnly
                                    />
                                    {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_agency)} className={"w-100"}>End agency</Button>*/}
                                </div>
                            )}
                            <div className={"w-100 my-3"}>
                                <p>Date de retour</p>
                                <div className={"d-flex w-100"}>
                                    <div className={"pe-1 w-100"}>
                                        <input
                                            className={cx("form-control px-1 text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.end_day})}
                                            placeholder={"date"}
                                            id={'endDate'}
                                            onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_day)}
                                            readOnly
                                            value={inputModeValues?.end_day ?? ""}
                                        />
                                        {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_date)} className={"w-100 mx-1"}>Start hour</Button>*/}
                                    </div>
                                    <div className={" w-75"}>
                                        <input
                                            className={cx("form-control text-center no-border shadow-none pointer", {'bg-main-light': displayMode === searchModalDisplayMode.end_hour})}
                                            placeholder={"heure"}
                                            onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_hour)}
                                            readOnly
                                            value={inputModeValues?.end_hour ?? ""}
                                        />
                                        {/*<Button onClick={() => handleDisplayModeChange(searchModalDisplayMode.end_hour)} className={"w-100"}>End hour</Button>*/}
                                    </div>
                                </div>
                            </div>

                            {warningMessage && <p className={"fs12 medium mt-4 fst-italic"} style={{color: "#ff4f4a"}}>{warningMessage}</p>}
                        </div>
                    </>
                    // <AgencyPicker agencies={agencies} name={"start_agency"} selected={search.start_agency} onChange={handleChange} checkColor={color} />
                )}
                {displayMode === searchModalDisplayMode.start_agency && !disableAgencySelect && (
                    <AgencyPicker agencies={agencies} name={"start_agency"} selected={search.start_agency} onChange={handleChange} checkColor={color} />
                )}
                {displayMode === searchModalDisplayMode.start_day && (
                    <DatePicker
                        mode={'start'}
                        onSelect={handleChange}
                        name={"start_day"}
                        startDateStr={search.start_day}
                        endDateStr={search.end_day}
                        unavailableDays={startUnavailableDays}
                    />
                )}
                {displayMode === searchModalDisplayMode.start_hour && (
                    <TimePicker
                        day={search.start_day}
                        mode={'start'}
                        name={'start_hour'}
                        // withLimit={search.start_day !== '' && search.start_day === search.end_day }
                        max={search.end_hour}
                        value={search.start_hour}
                        onChange={handleChange}
                        onBackButtonClick={handleBack}
                        hourDiff={minHours}
                        agencyHours={startAgencyHours}
                        agency={startAgency}
                        hoursAndMinutes={startHoursAndMinutes}
                    />
                )}
                {displayMode === searchModalDisplayMode.end_agency && !disableAgencySelect && (
                    <AgencyPicker agencies={agencies} name={"end_agency"} selected={search.end_agency} onChange={handleChange} checkColor={color}/>
                )}
                {displayMode === searchModalDisplayMode.end_day && (
                    <DatePicker
                        mode={'end'}
                        onSelect={handleChange}
                        name={"end_day"}
                        startDateStr={search.start_day}
                        endDateStr={search.end_day}
                        unavailableDays={endUnavailableDays}
                    />
                )}
                {displayMode === searchModalDisplayMode.end_hour && (
                    <TimePicker
                        day={search.end_day}
                        mode={'end'}
                        name={'end_hour'}
                        withLimit={search.start_day !== '' && search.start_day === search.end_day}
                        min={search.start_hour}
                        value={search.end_hour}
                        onChange={handleChange}
                        hourDiff={minHours}
                        onBackButtonClick={handleBack}
                        agencyHours={endAgencyHours}
                        agency={endAgency}
                        hoursAndMinutes={endHoursAndMinutes}
                    />
                )}
            </ModalBody>
            {onSearch && (
                <div className={"d-xl-none"}>
                    <ModalFooter >
                        <Button className={"w-100"} onClick={handleSearchButtonClick}>Rechercher</Button>
                    </ModalFooter>
                </div>
            )}
        </Modal>
    );
};

export default SearchModal;
