import React from 'react';
import {Divider} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight, faArrowRight} from "@fortawesome/free-solid-svg-icons";

const AgencyHours = ({availabilities}) => {
    return (
        <div className={"mt-2"}>
            {availabilities?.length > 0 && availabilities[0].status != 'unavailable' && <p className={"text-center"}>Horaires d'ouverture</p>}
            <Divider />
            {availabilities?.map(item => {
                return <div key={`agency_open_hour_${item.day}_${item.start_hour}`} className={"d-flex flex-row justify-content-center align-items-center"}>
                    {item.start_hour === "00:00" && item.end_hour === "23:59" ? (
                        <>
                            {item.status === 'unavailable' ? (
                                <p className={"my-1"}>Fermé</p>
                            ):(
                                <p className={"my-1"}>Ouvert toute la journée</p>
                            )}
                        </>
                    ):(
                        <>
                            <p className={"medium px-2 bradius-5"}>{item.start_hour}</p>
                            <p className={"mx-2 fs14"}><FontAwesomeIcon icon={faArrowAltCircleRight} /></p>
                            <p className={"medium px-2 bradius-5"}>{item.end_hour}</p>
                        </>
                    )}
                </div>
            })}
        </div>
    );
};

export default AgencyHours;
